import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

const settings = {timestampsInSnapshots: true};

const config = {
    apiKey: "AIzaSyAvFPRyl10wnoopv1Gy-r2nNDYbycDZNPg",
    authDomain: "patientmirror.firebaseapp.com",
    databaseURL: "https://patientmirror.firebaseio.com",
    projectId: "patientmirror",
    storageBucket: "patientmirror.appspot.com",
    messagingSenderId: "435239240632",
    appId: "1:435239240632:web:1be8ef8af155630702054d",
    measurementId: "G-Z6R2D90D52"
};
firebase.initializeApp(config);


export default firebase;