import React ,{useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";

import MenuItem from '@material-ui/core/MenuItem';

import Camera from 'react-html5-camera-photo';


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Grid from "@material-ui/core/Grid";

import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import Paper from "@material-ui/core/Paper";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Slide from "@material-ui/core/Slide";

import Modal from "@material-ui/core/Modal";
import Checkbox from '@material-ui/core/Checkbox';

import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp";
import Switch from "@material-ui/core/Switch";

import MaterialTable from "material-table";
import firebase from '../firebase'
import Autocomplete from "@material-ui/lab/Autocomplete";

import FormControl from "@material-ui/core/FormControl";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    marginLeft: "13em",
    marginTop: "5em",
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  }
}));

export default function AddPatient() {
  const classes = useStyles();
  const [dataUri, setDataUri] = React.useState('');
  const [checkedTakePhoto, setCheckedTakePhoto] = React.useState(true);
  const [checkedUploadImage, setCheckedUploadImage] = React.useState(false);
  const [doctor, setDoctor]=React.useState(true)
  const [employee,setEmployee]=React.useState(false)
  const [firstName,setfirstName]=React.useState('')
  const [lastName,setlastName]=React.useState('')
  const [fatherName,setfatherName]=React.useState('')
  const [mobile,setMobile]=React.useState('')
  const [CNIC,setCNIC]=React.useState('')
  const [email,setEmail]=React.useState('')
  const [phone,setPhone]=React.useState('')
  const [network,setNetwork]=React.useState('Select')
  const [address,setAddress]=React.useState('')
  const [passort,setPassport]=React.useState('')
  const [Fax,setFax]=React.useState('')
  const [Ref,setRef]=React.useState('')
  const [district,setDistrict]=React.useState('')
  const [Designation,setDesignation]=React.useState('')
  const [panel,setPanel]=React.useState('')
  const [Date_Birth,setDate_Birth]=React.useState('')
  const [gender,setGender]=React.useState('Select')
  const [employeeName,setEmployeeName]=React.useState('')  
  const [relation,setRelation]=React.useState('')
  const [title,setTitle]=React.useState('')

  useEffect(()=>{
    firebase.database().ref('patient').on('value',data=>{
     var record= data.toJSON()
    setfirstName(record.FirstName)
    setlastName(record.LastName)
    setNetwork(record.Network)
    setGender(record.Gender)
    setTitle(record.Title)
    setEmail(record.Email)
    setMobile(record.Mobile)
    setDate_Birth(record.DOB)
    })
  },[])

  const handleChangeTake = (event) => {
    setCheckedTakePhoto(event.target.checked);
    setCheckedUploadImage(!event.target.checked)
  };
  const handleChangeUpload = (event) => {
    setCheckedUploadImage(event.target.checked);
    setCheckedTakePhoto(!event.target.checked)
  };

 
  function handleTakePhotoAnimationDone (dataUri) {
    console.log(dataUri);
    setDataUri(dataUri);
  }
  
  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off">
        <div>
            <Grid container >
                <Grid item xs={4} sm={4} md={4} lg={4}>
                <TextField required id="" style={{width:"90%"}}
                  value={title}
                  onChange={(e)=>{
                    setTitle(e.target.value)
                  }}
                  label="Title"
                  
                  />
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4}>
      <TextField required id="" style={{width:"90%"}}
                  value={firstName}
                  onChange={(e)=>{
                    setfirstName(e.target.value)
                  }}
                  label="First Name"
                  
                  />
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4}>
      <TextField required id="" style={{width:"80%"}}
                      value={lastName}
                      onChange={(e)=>{
                        setlastName(e.target.value)
                      }}
                  label="Last Name"
                  
                  />
      </Grid>
      
      </Grid>
      <br />
      <Grid container>
          <Grid item xs={4} sm={4} md={4} lg={4}>
          <TextField required id="" style={{width:"80%"}}
                      value={Date_Birth}
                      onChange={(e)=>{
                        setDate_Birth(e.target.value)
                      }}
                  label="Date of Birth"
                  
                  />
          
              </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} >
      
      <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Gender</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={gender}
          onChange={(e)=>{
            setGender(e.target.value)
          }}
        >
        <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          <MenuItem value={"Male"}>Male</MenuItem>
          <MenuItem value={"Female"}>Female</MenuItem>
        </Select>
      </FormControl>
</Grid>
<Grid item xs={4} sm={4} md={4} lg={4} >
<TextField required id="" style={{width:"80%"}}
            value={email}
            onChange={(e)=>{
              setEmail(e.target.value)
            }}
                  label="Email" 
                  />
    </Grid>
          </Grid>
          <br />
          <Grid container>

<Grid item xs={4} sm={4} md={4} lg={4} >
<FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Mobile Network</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={network}
          onChange={(e)=>{
            setNetwork(e.target.value)
          }}
        >
           <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          <MenuItem value={"Ufone"}>Ufone</MenuItem>
          <MenuItem value={"Zong"}>Zong</MenuItem>
          <MenuItem value={"Mobilink"}>Mobilink</MenuItem>
          <MenuItem value={"Telenor"}>Telenor</MenuItem>
          <MenuItem value={"Warid"}>Warid</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={4} sm={4} md={4} lg={4}>
      <TextField required id="" style={{width:"80%"}}
        value={mobile}
        onChange={(e)=>{
          setMobile(e.target.value)
        }}
                  label="Mobile Number" 
                  />
</Grid>
          </Grid>
          <br />
          <Grid container>
            
          <Grid item xs={4} sm={4} md={4} lg={4}>
        
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
     
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
              </Grid>
              </Grid>
                        

      
</div>
</form>
</div>
  );
}
