import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Slide from "@material-ui/core/Slide";

import Modal from "@material-ui/core/Modal";

import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp";
import Switch from "@material-ui/core/Switch";

import Invoice_Table from "./table";

import MaterialTable from "material-table";

import Autocomplete from "@material-ui/lab/Autocomplete";

import FormControl from "@material-ui/core/FormControl";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    marginLeft: "13em",
    marginTop: "5em",
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  },
}));
function msToTime(ms) {
  var seconds = ms / 1000;
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ":" + minutes;
}

export default function Invoice() {
  const classes = useStyles();
 const [classicModal,setClassicModal]=React.useState(false)
  const [search_table, setSearch_table] = React.useState([]);
  const [age, setAge] = React.useState("");
  const [lab_tests, setLab_test] = React.useState([]);
  const [state, setState] = React.useState({
    columns: [
      { title: "Code", field: "code" },
      { title: "Title", field: "title" },
      { title: "Description", field: "description" },
      { title: "Cost", field: "cost" },
      { title: "Disc %", field: "discount_percent" },
      { title: "Disc Value", field: "discount_value" },
      { title: "Special Disc", field: "special_discount" },
      { title: "Net Cost", field: "net_cost" },
    ],
    data: [],
  });
  const [patient_history, setPatientHistory] = React.useState([]);
  const [panel, setPanel] = React.useState("");
  const [total_bill, setTotal_Bill] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectOption, setSelectOption] = React.useState({});
  const [openHist, setOpenHist] = React.useState();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenHist = () => {
    setOpenHist(true);
  };
  function calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseHist = () => {
    setOpenHist(false);
  };
  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [id, setId] = React.useState("");
  const search_patient = () => {
    fetch(
      "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetsPatientsList?vName=" +
        name +
        "&vMobile=" +
        mobile +
        "&vID=" +
        id
    )
      .then((res) => res.json())
      .then((response) => {
        var data = [];
        response.map((item) => {
          var obj = {
            Patient_ID: item.Patient_ID,
            Patient_Name: item.Patient_Name,
            MobileNo: item.MobileNo,
            ToAddress: item.ToAddress,
            eMail: item.eMail,
            select: (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  Populate(
                    item.Patient_ID,
                    item.Patient_Name,
                    item.MobileNo,
                    item.Panel_Name,
                    item.Patient_DOB
                  );
                }}
              >
                Select
              </Button>
            ),
          };

          data.push(obj);
        });
        setSearch_table(data);
        handleOpen(true);
      });
  };
  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? "" : "0") + dd,
      (mm > 9 ? "" : "0") + mm,
      this.getFullYear(),
    ].join("/");
  };
  const Call_History = () => {
    if (id != undefined && id.trim() != "") {
      var from = new Date("1-1-2020");
      var to = new Date();
      fetch(
        "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestListwithStatus?vQuery={%22PIN%22:%22%22,%22PhoneNo%22:%22%22,%22PatientName%22:%22%22,%22RegDateFrom%22:%22" +
          from.toISOString() +
          "%22,%22RegDateTo%22:%22" +
          to.toISOString() +
          "%22}&vUID=" +
          id +
          "&vRole=Patient"
      )
        .then((res) => res.json())
        .then((result) => {
          var the_rows = [];
          result.map((data) => {
            var ds = new Date(data.ACCESS_DATE).ddmmyyy();
            var time = msToTime(data.ACCESS_TiME);
            var obj = {
              id: data.Invoice_ID,
              name: data.NAME_F + " " + data.NAME_L,
              title: data.T_TiTLE,
              status: data.Test_Status,
              date: ds + " " + time,
              code: data.T_CODE,
              dateObject: new Date(data.ACCESS_DATE),
              download: (
                <center>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => {
                      if (data.Test_Status == "Results Ready") {
                        window.open(
                          "https://reports.mpl-labs.pk:8443/Scripts/pdf.js/web/viewer.html?file=/4DACTION/GetReportPDF/" +
                            data.InV_AuX_ID
                        );
                      } else {
                        alert("Results not Ready!!");
                      }
                    }}
                  >
                    <PictureAsPdfSharpIcon />
                  </Button>
                </center>
              ),
            };
            the_rows.push(obj);
          });
          console.log(the_rows);
          setPatientHistory(the_rows);
          handleOpenHist();
        });
    } else {
      alert("Kindly Search Patient First!");
    }
  };
  const Populate = (id, name, mobile, panel, dob) => {
    fetch(
      "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetPatientSTestPrices?vQuery={%22PIN%22:%22%22,%22PhoneNo%22:%22%22,%22PatientName%22:%22%22,%22RegDateFrom%22:%222000-02-01T04:08:27.995Z%22,%22RegDateTo%22:%222020-08-12T04:08:27.995Z%22}&vUID=" +
        id +
        "&vRole=Patient"
    )
      .then((res) => res.json())
      .then((response) => {
        setLab_test(response);
        response.map((item) => {
          var obj = {
            code: item.Test_Code,
            title: item.Test_Name,
            description: item.Sample_Type,
            cost: item.Test_Price,
            net_cost: item.Test_Price,
            discount_percent: "0%",
            discount_value: "0",
            special_discount: "0",
          };
          var reports = selectOption;
          reports[item.Test_Code.toUpperCase()] = obj;
          setSelectOption(reports);
        });
      });
    setId(id);
    setName(name);
    var age = new Date(dob);
    setAge(calculateAge(age));
    setMobile(mobile);
    setPanel(panel);
    handleClose(false);
  };
  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off">
        <div>
          <TextField
            id="date"
            label="Date"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            id="outlined-required"
            label="Panel (if related)"
            value={panel}
            variant="outlined"
            style={{ width: "25em" }}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Scan Documents
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
             window.location.href="http://localhost:3000/AddPatient"
            }}
          >
            Add New Patient
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Call a Group of Tests
          </Button>
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="Booking ID"
            variant="outlined"
          />
          <TextField
            required
            id="outlined-required"
            label="Patient Age"
            value={age}
            variant="outlined"
          />
          

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              Call_History();
            }}
          >
            Patient History
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Add a Blank Line
          </Button>
        </div>
        <div style={{display:'flex', right:0, position:'absolute'}}>
            <b>Attach PDF</b>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
                 <input type="file" variant="contained"
            color="primary"
            className={classes.button} 
            name="my_file[]" 
            aria-label="Attach PDF"
          
            multiple />
          </Button>
          </div>
        <div style={{ display: "-webkit-inline-box", width: "100%" }}>
          <TextField
            required
            id="outlined-required"
            label="Patient ID"
            value={id}
            onChange={(e) => {
              setId(e.target.value);
            }}
            variant="outlined"
          />

          <TextField
            required
            id="outlined-required"
            label="Patient Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            variant="outlined"
          />
          <TextField
            required
            id="outlined-required"
            value={mobile}
            onChange={(e) => {
              setMobile(e.target.value);
            }}
            label="Patient Mobile"
            variant="outlined"
          />
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" }}
            onClick={() => {
              setPanel("");
              setId("");
              setMobile("");
              setName("");
              setAge("");
              setSearch_table([]);
              setLab_test([]);
              setTotal_Bill(0);
              setState((prevState) => {
                var data = [];
                return { ...prevState, data };
              });
            }}
            className={classes.button}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" }}
            onClick={() => {
              search_patient();
            }}
            className={classes.button}
          >
            Search
          </Button>
        </div>

        <div>
          <FormControlLabel
            className={classes.switch}
            control={<Switch value="" />}
            label="Lab Tests"
          />
          <FormControlLabel
            className={classes.switch}
            control={<Switch value="" />}
            label="Hospital Service"
          />
          <FormControlLabel
            className={classes.switch}
            control={<Switch value="" />}
            label="Room Book"
          />
          <FormControlLabel
            className={classes.switch}
            control={<Switch value="" />}
            label="Blood Bank"
          />
          <FormControlLabel
            className={classes.switch}
            control={<Switch value="" />}
            label="All Together"
          />
          <FormControlLabel
            style={{
              padding: "10px",
              border: "1px solid black",
              fontWeight: "bolder",
            }}
            className={classes.switch}
            control={<Switch value="" color="secondary" />}
            label="Include Previous Report Results"
          />
        </div>
        <div>
          <Autocomplete
            id="combo-box-demo"
            options={lab_tests}
            getOptionLabel={(option) => option.Test_Code.toUpperCase()}
            onSelect={(e) => {
              var test = e.target.value.toUpperCase();
              if (test.trim() != "") {
                var result = selectOption[test];
                if (result != undefined) {
                  setState((prevState) => {
                    const data = [...prevState.data];
                    if (!data.includes(result)) {
                      data.push(result);
                      var price = parseInt(result.net_cost) + total_bill;
                      setTotal_Bill(price);
                      return { ...prevState, data };
                    }
                    return { ...prevState, data };
                  });
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "23em" }}
                label="Services"
                variant="outlined"
              />
            )}
          />
        </div>
        <div>
          <Grid container>
            <Grid xs={1} sm={1} md={1} lg={1}></Grid>
            <Grid xs={10} sm={10} md={10} lg={10}>
              <MaterialTable
                columns={state.columns}
                data={state.data}
                options={{
                  paging: false,
                  maxBodyHeight: "20em",
                  minBodyHeight: "20em",
                  showTitle: false,
                  search: false,
                  headerStyle: { position: "sticky", top: 0 },
                }}
                editable={{
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        setState((prevState) => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          var price = total_bill - parseInt(oldData.net_cost);
                          setTotal_Bill(price);
                          return { ...prevState, data };
                        });
                      }, 600);
                    }),
                }}
              />
            </Grid>
            <Grid xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid container>
            <Grid xs={3} sm={3} md={3} lg={3}></Grid>
            <Grid xs={2} sm={2} md={2} lg={2}>
              <TextField
                required
                id="outlined-required"
                label="Amount"
                variant="outlined"
              />
            </Grid>
            <Grid xs={2} sm={2} md={2} lg={2}>
              <TextField
                required
                id="outlined-required"
                label="Special Discount"
                variant="outlined"
              />
            </Grid>

            <Grid xs={2} sm={2} md={2} lg={2}>
              <TextField
                required
                id="outlined-required"
                label="Special Discount %"
                variant="outlined"
              />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3}></Grid>
          </Grid>
          <Grid container>
            <Grid xs={4} sm={4} md={4} lg={4}></Grid>
            <Grid xs={2} sm={2} md={2} lg={2}>
              <Paper
                style={{
                  marginTop: "0.5em",
                  padding: "1em",
                  textAlign: "center",
                }}
              >
                <b>Payable</b>
              </Paper>
            </Grid>

            <Grid xs={2} sm={2} md={2} lg={2}>
              <Paper
                style={{
                  marginTop: "0.5em",

                  padding: "1em",
                  textAlign: "center",
                }}
              >
                <b>{total_bill}</b>
              </Paper>
            </Grid>
            <Grid xs={4} sm={4} md={4} lg={4}></Grid>
          </Grid>
        </div>
      </form>
      <br />
      <br />
      <div style={{ display: "-webkit-inline-box", width: "100%" }}>
        <Autocomplete
          id="combo-box-demo"
          options={patients}
          getOptionLabel={(option) => option.id}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ width: "23em" }}
              label="Ref By"
              variant="outlined"
            />
          )}
          style={{ width: "25em" }}
        />
        <TextField
          required
          id="outlined-required"
          label="Ref No #"
          variant="outlined"
        />
        <Autocomplete
          id="combo-box-demo"
          options={patients}
          getOptionLabel={(option) => option.id}
          renderInput={(params) => (
            <TextField {...params} label="Sales Agent" variant="outlined" />
          )}
        />
      </div>
      <div style={{ width: "100%" }}>
        <TextField
          id="standard-basic"
          label="Comments for Patient"
          multiline={true}
          style={{
            height: "5em",
            width: "100%",
          }}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <MaterialTable
            columns={[
              { field: "Patient_ID", title: "Patient ID" },
              { field: "ToAddress", title: "Title" },
              { field: "Patient_Name", title: "Patient Name" },
              { field: "MobileNo", title: "Mobile" },
              { field: "eMail", title: "Email" },
              { field: "select", title: "Select" },
            ]}
            data={search_table}
            title={"Patient"}
          />
        </div>
      </Modal>
     

      <Modal
        open={openHist}
        onClose={handleCloseHist}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <MaterialTable
            columns={[
              { field: "id", title: "Invoice ID" },
              { field: "name", title: "Patient Name" },
              { field: "date", title: "Reg. Date / Time" },
              { field: "title", title: "Test Name" },
              { field: "code", title: "Test Code" },
              { field: "status", title: "Status" },
              { field: "download", title: "View Report" },
            ]}
            data={patient_history}
            title={"Patient History"}
          />
        </div>
      </Modal>
    </div>
  );
}
const patients = [
  { name: "Rehan Raees", id: "mpl101", mobile: "03315999501" },
  { name: "Ali", id: "mpl2338", mobile: "03005031668" },
];
